.detail-page {
  position: relative;
  .background-pattern1 {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #ecececba;
    transform: skewY(12deg);
    z-index: -1;
  }
  .page-content {
    max-width: 1600px;
    margin: 0 auto;
    .project-slider {
      margin: 3rem 2rem;
      .section-content {
        .title {
          text-align: center;
          margin-bottom: 4.2rem;
        }
        .slider-content {
          margin: 0 auto;
          position: relative;

          .slick-slider {
            .slider-item {
              .item-img {
                width: 100%;
                text-align: center;

                img {
                  width: 100%;
                  margin: auto;
                }
              }
            }

            .slick-dots {
              margin-bottom: 60px;
              li button:before {
                content: "";
                width: 16px;
                height: 16px;
                background: black;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    .project-header {
      margin-bottom: 2rem;
      position: relative;

      .background-header {
        position: relative;
        .background-title {
          z-index: 1;
          position: absolute;
          right: 0;
          left: 0;
          top: 10px;
          font-family: Radnika-Bold, sans-serif;
          font-style: normal;
          font-weight: bold;
          text-align: center;
          font-size: 68px;
          line-height: 82px;
          color: #F1F1F1;
        }

        .triangle {
          position: absolute;
          right: 30%;
          z-index: 1;
          animation: heartbeat 2s infinite;
        }

        .big-circle {
          position: absolute;
          width: 40px;
          height: 40px;
          border-radius: 20px;
          top: 140px;
          left: 6rem;
          border: 4px solid #619871;
          animation: heartbeat 1.2s infinite;
        }
        @keyframes heartbeat {
          0%
          {
            transform: scale( .75 );
          }
          20%
          {
            transform: scale( 1 );
          }
          40%
          {
            transform: scale( .75 );
          }
          60%
          {
            transform: scale( 1 );
          }
          80%
          {
            transform: scale( .75 );
          }
          100%
          {
            transform: scale( .75 );
          }
        }

        .cicle-pattern {
          display: flex;
          position: absolute;
          right: 40px;
          top: 10px;
          flex-wrap: wrap;
          width: 40px;
        }

      }

      .title-header {
        position: relative;
        z-index: 10;

        h1 {
          padding-top: 5rem;
          font-family: Radnika-Bold, sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 48px;
          line-height: 58px;
          text-align: center;

          color: #263366;

        }

        .symbol {
          background: #FF977F;
          height: 6px;
          width: 100px;
          margin: 1rem auto;
        }
      }
    }
    .project-detail {
      .property-desc {
        font-family: Radnika-Light, sans-serif;
        font-size: 1.6rem;
        color: #606060;
        a {
          text-decoration: underline;
          color: #606060;
          font-style: italic;
        }
      }
      .project-property {
        .technologies {
          font-family: Radnika-Light, sans-serif;
          font-size: 1.4rem;
          color: #606060;
        }
      }
      .links {
        .icon {
          font-size: 1.5rem;
          color: #273366;
          margin-right: 24px;
        }
      }
      .section-narator {
        font-size: 1.8rem;
        color: #273366;
        border-bottom: 1px solid #54545421;
        padding-bottom: 6px;
      }
    }
  }
}

*, *::before, *::after {
  -moz-box-sizing: border-box;
  box-sizing: border-box;

  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
}

.project-item {
  padding-right: 1rem !important;
  padding-left: 0 !important;
  figure {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: inline-block;
    vertical-align: top;
    border: none;
    box-shadow: 0 0 5px #ddd;
    margin: 0 0 1em !important;
    img {
      width: 100%;
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear;
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
    &:hover img {
      -webkit-transform: scale3d(1.2, 1.2, 1);
      transform: scale3d(1.2, 1.2, 1);
      opacity: 0.8;
    }
  }

  figcaption {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    &:hover {
      .project-title {
        opacity: 1;
        padding: 16px;
        bottom: 0;
        top: unset;
        position: absolute;
        background: rgb(36 118 66 / 80%);
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;

        span {
          font-family: Nunito Sans, sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 1.8rem;
          text-decoration: underline;
          text-decoration-color: white;
          text-underline-offset: 4px;
          text-decoration-thickness: 3px;
        }
      }
    }
    .project-title {
      opacity: 0;
      width: 100%;
      color: #ffffff;
      font-family: Nunito Sans, sans-serif;
      font-style: normal;
      font-size: 1.8rem;
      font-weight: 800;
      line-height: normal;
      text-transform: uppercase;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

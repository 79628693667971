@font-face {
  font-family: "Radnika-Black";
  src: url('./Radnika-Black.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-BlackCondensed";
  src: url('./Radnika-BlackCondensed.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-BlackItalic";
  src: url('./Radnika-BlackItalic.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-Bold";
  src: url('./Radnika-Bold.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-BoldCondensed";
  font-weight: 100;
  src: url('./Radnika-BoldCondensed.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-BoldItalic";
  font-weight: 100;
  src: url('./Radnika-BoldItalic.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-Condensed";
  font-weight: 100;
  src: url('./Radnika-Condensed.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-Italic";
  font-weight: 100;
  src: url('./Radnika-Italic.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-Light";
  font-weight: 100;
  src: url('./Radnika-Light.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-LightCondensed";
  font-weight: 100;
  src: url('./Radnika-LightCondensed.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-LightItalic";
  src: url('./Radnika-LightItalic.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-Medium";
  src: url('./Radnika-Medium.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-MediumCondensed";
  src: url('./Radnika-MediumCondensed.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-MediumItalic";
  src: url('./Radnika-MediumItalic.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-Regular";
  src: url('./Radnika-Regular.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-SemiBold";
  src: url('./Radnika-SemiBold.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-SemiBoldCondensed";
  src: url('./Radnika-SemiBoldCondensed.ttf') format('opentype');
}

@font-face {
  font-family: "Radnika-SemiBoldItalic";
  src: url('./Radnika-SemiBoldItalic.ttf') format('opentype');
}

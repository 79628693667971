.container {
  width: 100%;
  height: 650px;
  position: relative;
  background-color: clear;
}

.container svg {
  display: block;
  width: 100%;
  height: 100%;
}

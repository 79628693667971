@import './assets/fonts/Roboto/index.scss';
@import './assets/fonts/Radnika/index.scss';
@import 'bootstrap/dist/css/bootstrap.min.css';

html {
  font-size: 100%;
  font-family: Radnika-Bold, sans-serif;

  @media screen and (max-width: 768px) {
    font-size: 80%;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: Radnika-Regular, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}

.root {
  overflow: hidden;
}

button:active, button:focus {
  outline: none;
}

input:active, input:focus, textarea:active, textarea:focus {
  outline: none;
}

a {
  text-decoration: none;
}

.xl-font {
  font-size: 6.5rem;
  font-family: Radnika-Regular, sans-serif;
  line-height: 7rem;
  color: #666;
}

.lg-font {
  font-size: 4.2rem;
  font-family: Radnika-Regular, sans-serif;
  color: #666;
}

.md-font {
  font-size: 2.4rem;
  color: #666;
}

.sm-font {
  font-size: 1.6rem;
  color: #666;
}

.default-btn {
  width: fit-content;
  border: none;
  display: block;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  outline: none;
  overflow: hidden;
  position: relative;
  color: #fff;
  font-weight: 700;
  font-size: 1.6rem;
  background-color: #ff5831;
  padding: 1.7rem 6rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.20);

  span {
    position: relative;
    z-index: 1;
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 23rem;
    width: 27rem;
    background: #f5ad9c;
    -webkit-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    -webkit-transform: translateX(-98%) translateY(-25%) rotate(45deg);
    transform: translateX(-98%) translateY(-25%) rotate(45deg);
  }

  &:hover:after {
    -webkit-transform: translateX(-9%) translateY(-25%) rotate(45deg);
    transform: translateX(-9%) translateY(-25%) rotate(45deg);
  }
}



@media (max-width: 575px) {
  .container, .container-fluid {
    width: 90% !important;
  }
}


.section-header {
  text-align: center;

  .title {
    font-family: Radnika-Bold, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: normal;
    text-transform: uppercase;
    color: #F24B24;
    text-align: center;
  }

  .desc {
    font-family: Radnika-Regular, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: normal;
    text-align: center;
    color: #000000;
    margin-top: 12px;
  }

  .symbol {
    background: #FF977F;
    height: 4px;
    width: 100px;
    margin: 2rem auto;
  }
}


.section-title {
  margin: 5rem 1rem;
  position: relative;

  .title {
    font-family: Radnika-Bold, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: normal;
    text-align: center;
    color: #247642;
    margin: 5rem 1rem;
    position: relative;
    @media (max-width: 768px) {
      font-size: 36px;
    }

    &:after {
      content: ' ';
      position: absolute;
      width: 80px;
      height: 6px;
      margin: auto;
      left: 0;
      right: 0;
      bottom: -1.2rem;
      background: #f36c6b;
    }
  }

  .title-shadow {
    font-weight: bold;
    font-family: Radnika-Bold, sans-serif;
    font-size: 68px;
    text-align: center;
    color: #263366;
    opacity: 0.05;
    background: transparent;
    position: absolute;
    top: -5rem;
    right: 0;
    left: 0;

    @media (max-width: 768px) {
      font-size: 48px;
    }

  }
}

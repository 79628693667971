.case-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  .project-top-header {
    position: relative;
    background-image: url("../../assets/img/site/technical-labs-banner-bg.svg");

    background-position: center;
    background-size: cover;
    min-height: 160px;
    .project-header {
      padding: 1rem 0;
      position: relative;

      .section-title {
        margin: 0;
        .title {
          margin: 0;
          color: #ffffff;
          font-size: 4rem;

          &::after {
            background: #F26C6C;
            bottom: -2rem;
            width: 120px;
            height: 8px;
          }
        }
      }

      .circles-pattern {
        position: absolute;
        left: 30%;
        top: 55%;
        opacity: 0.4;
        @media (max-width: 767px) {
          display: none;
        }
      }
    }
  }
  .case-studies-container {
    position: relative;
    .background-pattern1 {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #ecececba;
      transform: skewY(8deg);
      z-index: -1;
    }
    .case-studies {
      max-width: 1800px;
      margin: 0 auto;
      padding: 1rem 1rem 0 2rem;
      .title {
        margin-bottom: 4.2rem;
      }

      .case-studies-content {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
}

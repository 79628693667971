.technical-labs-page {
  .about {
    max-width: 1600px;
    margin: 0 auto;
    padding: 2rem 40px;
    min-height: 600px;
    display: flex;
    align-items: center;
    div[class*="col-"] {
      padding: 2rem;
    }

    position: relative;
    background-image: url("../../assets/img/site/step2-bg.svg");
    background-position: right center;
    background-repeat: no-repeat;
    background-size: 60%;
    @media (max-width: 767px) {
      background-size: 100%;
    }
    .bg-col {
      display: flex;
      align-items: center;
    }

    p {
      font-family: Radnika-Light, sans-serif;
      font-size: 1.8rem;
      line-height: 3.6rem;
      color: #606060;
      opacity: 0.75;
      margin-top: 2rem;
    }
    .section-title {
      margin-left: 0;
      .title {
        margin-left: 0;
        text-align: left;
        &::after {
          right: unset;
          bottom: -1rem;
        }
      }

      .title-shadow {
        text-align: left;
      }
    }
  }

  .tech-use {
    position: relative;
    background: white;
    .background-pattern1 {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: #ecececba;
      transform: skewY(-8deg);
      border-radius: 0 30% 0 0;
      z-index: 0;
    }
    .tech-container {
      max-width: 1600px;
      margin: 0 auto;
      padding: 3rem 0;
      position: relative;

      .technologies {
        padding: 3rem;
      }
    }
  }
}

.home-page {
  overflow: hidden;
  position: relative;
  background-color: #E5E5E588;

  .top-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../../assets/img/site/banner-header.png") no-repeat center;
    -webkit-background-size: cover;
    background-size: cover;
    border-radius: 0 0 50px 0;
    min-height: 650px;
    .content {
      max-width: 1600px;
      .hero {
        position: relative;
        padding: 0 2rem 4rem;
        .illustration {
          position: absolute;
          margin: auto;
          width: 36%;
          right: 5%;
          top: 0;
          bottom: 0;
        }
        .team-desc {
          z-index: 1;
          .title {
            color: #FFFFFF;
            font-family: Radnika-Bold, sans-serif;
            font-weight: 600;
            font-size: 4rem;
            line-height: 5rem;
          }

          .desc {
            font-family: Radnika-Light, sans-serif;
            font-weight: normal;
            font-size: 1.6rem;
            line-height: 3rem;
            margin-top: 5rem;
            color: #FFFFFF;
            span {
              font-size: 2rem;
              color: #F26C6C;
              text-decoration: underline;
              font-family: Radnika-Bold, sans-serif;
            }
          }

        }

        .info {
          position: relative;
          .circles-pattern {
            position: absolute;
            top: 4vh;
            right: 2vw;

            img {
              width: 75px;
              @media (max-width: 767px) {
                width: 65px;
              }
            }
          }
        }
      }
    }
  }

  .vertical-lines-bg {
    background-image: url("../../assets/img/site/Vertical-line.svg");
    background-position: top center;
    background-size: auto 90%;
    background-repeat: no-repeat;
    .about {
      max-width: 1600px;
      position: relative;
      margin: 0 auto;
      padding: 5rem 1rem 1rem 1rem;

      .about-paragraph {
        width: 100%;
        padding: 0 2rem;
        margin: auto;
        .introduction {
          margin: 2rem auto;
          .desc {
            font-family: Radnika-Light, sans-serif;
            font-size: 1.8rem;
            line-height: 3.6rem;
            color: #606060;
            text-align: justify;
            margin: 5rem 0 3rem 0;
          }
        }
      }
    }
  }

  .services {
    padding-top: 1rem;
    margin: 0rem auto 1rem auto;
    .features-content {
      margin: 0 auto;
      .item {
        text-align: center;
        padding: 3rem 0;
        font-size: 28px;
        line-height: 34px;
        cursor: pointer;
        &:hover {
          background: #FFFFFF;
          box-shadow: 0 8px 38px rgba(3, 3, 3, 0.1);
          border-radius: 12px;
        }

        .icon-img {
          display: flex;
          justify-content: center;

          .icon {
            height: 100px;
          }
        }
        .title {
          font-family: Radnika-Bold, sans-serif;
          font-style: normal;
          font-weight: bold;
          font-size: 28px;
          line-height: 34px;
          text-align: center;
          color: #263366;
          margin-top: 16px;

        }
        .description {
          font-family: Nunito Sans, sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #808080;
          margin: 12px auto;
          width: 80%;
        }
      }
    }
  }

  .pattern-background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    padding: 6rem 0 6rem 0;
    position: relative;
    .background-pattern {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: white;
      transform: skewY(8deg);
    }
    .pattern-img {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      margin: auto;
      object-fit: cover;
      height: 100%;
    }
    .pattern-circles-top-right {
      position: absolute;
      top: 50px;
      right: 200px;
      width: 80px;

    }
    .pattern-circles-bottom-left {
      position: absolute;
      left: 200px;
      width: 80px;
    }
    .case-studies-container {
      max-width: 1600px;
      margin: 0 auto;
      padding: 0 2rem;
      .case-studies {
        padding: 0 0 2rem;

        text-align: center;
        .row {
          margin-left: 0;
          margin-right: 0;
        }
        .see-more {
          font-family: Radnika-Medium, sans-serif;
          font-size: 24px;
          color: #263366;
          background: white;;
          border: 1px solid #263366;
          box-sizing: border-box;
          border-radius: 2px;
          line-height: 29px;
          width: 200px;
          display: block;
          height: 50px;
          right: 0;
          margin: 0.6rem auto;
          left: 0;
          z-index: 5;
          position: relative;
          transition: all .4s ease-in-out;
          &:hover { transform: scale(1.1); }
        }
      }
    }
  }

  .testimonial {
    padding: 5rem 0;
    .project-slider {
      position: relative;
      .quot-right {
        position: absolute;
        bottom: 10px;
        right: 10px;
      }
      .quot-left {
        position: absolute;
        left: 10px;
        top: 10px;
      }

      .slider-content {
        margin: 0 auto;
        width: 90%;

        .slick-slider {
          .slider-item {
            .item-img {
              width: 60%;
            }
          }
          .slick-dots {
            bottom: -50px;
            li button:before {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}


@font-face {
  font-family: "Roboto-Black";
  src: url('./Roboto-Black.ttf') format('opentype');
}

@font-face {
  font-family: "Roboto-BlackItalic";
  src: url('./Roboto-BlackItalic.ttf') format('opentype');
}

@font-face {
  font-family: "Roboto-Bold";
  src: url('./Roboto-Bold.ttf') format('opentype');
}

@font-face {
  font-family: "Roboto-BoldItalic";
  src: url('./Roboto-BoldItalic.ttf') format('opentype');
}

@font-face {
  font-family: "Roboto-Light";
  font-weight: 100;
  src: url('./Roboto-Light.ttf') format('opentype');
}

@font-face {
  font-family: "Roboto-LightItalic";
  font-weight: 100;
  src: url('./Roboto-LightItalic.ttf') format('opentype');
}

@font-face {
  font-family: "Roboto-Medium";
  font-weight: 100;
  src: url('./Roboto-Medium.ttf') format('opentype');
}

@font-face {
  font-family: "Roboto-MediumItalic";
  font-weight: 100;
  src: url('./Roboto-MediumItalic.ttf') format('opentype');
}

@font-face {
  font-family: "Roboto-Thin";
  font-weight: 100;
  src: url('./Roboto-Thin.ttf') format('opentype');
}

@font-face {
  font-family: "Roboto-ThinItalic";
  font-weight: 100;
  src: url('./Roboto-ThinItalic.ttf') format('opentype');
}

@font-face {
  font-family: "Roboto-Regular";
  src: url('./Roboto-Regular.ttf') format('opentype');
}

@font-face {
  font-family: "Roboto-Italic";
  src: url('./Roboto-Italic.ttf') format('opentype');
}

.wrapper {
  display: flex;
  align-items: center;
  margin: 0 auto;
  padding: 28px;
  background: #fff;
  border-radius: 10px;
  max-width: 842px;
  min-height: 450px;
  @media (max-width: 767px) {
    padding: 28px 10px;
  }

  .quote {
    width: 100%;
    font-family: Radnika-LightItalic, sans-serif;
    font-style: normal;
    font-weight: 100;
    font-size: 24px;
    line-height: 48px;
    text-align: left;
    color: #606060;

  }

  .client-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    .picture {
      margin-bottom: 1rem;
      height: 80px;
      width: 80px;
      border-radius: 40px;
      object-fit: contain;
    }

    .name {
      margin-bottom: 6px;
      font-family: Radnika-Light, sans-serif;
      font-style: normal;
      font-weight: 100;
      font-size: 1.2rem;
      line-height: normal;
      text-align: left;
      color: #808080;
    }
    .job {
      font-family: Radnika-Light, sans-serif;
      font-style: normal;
      font-weight: 100;
      font-size: 1rem;
      line-height: normal;
      text-align: left;
      color: #808080;
    }
  }
}
